import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Loading from "../../components/Loading";
import { formatDate, formatDateTime, formatTime } from "../../../helpers/dates";
import { deleteUser, getUserWifi } from "../../../services/wifiService";
import { toast } from "react-toastify";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";
import { getWifiHosts } from "../../../services/WifiClientService";

function ListUsers({ sort, users_counts, paginate, currentPage }) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({});
  const [data, setData] = useState([]);
  const [sup, setSup] = useState(false);

  const getColorStatus = (statu) => {
    switch (statu) {
      case 1:
        return "success";
      case 0:
        return "danger";
      default:
        return "secondary";
    }
  };
  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };
  const notifyError = (message) => toast.error(message, toastOptions);
  const notifySuccess = (message) => toast.success(message, toastOptions);

  const supr = (id) => {
    setSup(true);
    deleteUser(id).then((res) => {
      setUsers(users.filter((val) => val.id != id));
      setSup(false);
      notifySuccess("Utilisateur supprimé");
    });
  };

  const getHosts = () => {
    setLoading(true);
    getWifiHosts()
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        toast.error("Erreur lors de la récupération des données", toastOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHosts();
  }, []);

  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc">Utilisateur</th>
              <th className="sorting_asc">Appareil</th>
              {/* <th className="sorting">Consomation</th> */}
              <th className="sorting">Temps d'utilisation</th>
              <th className="sorting">Action</th>

              {/* <th className="sorting">Consomation</th> */}
              {/* <th className="sorting">Status</th> */}
              {/* <th className="sorting"></th> */}
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    role="row"
                    className={index % 2 === 0 ? "odd" : "even"}
                    key={index}
                  >
                    <td>
                      <span>{item.address}</span>
                    </td>
                    <td>
                      <span> {item["mac-address"]} </span>
                    </td>
                    <td>
                      <span> {`${item.uptime}`} </span>
                    </td>
                    <td>
                      <span> {`${item.uptime}`} </span>
                    </td>
                    <td>
                      <CheckPermission permission="delete_user_wifi">
                        {item.id == "*1" || item.id == "*2" ? (
                          ""
                        ) : (
                          <button
                            className="btn btn-sm btn-warning"
                            disabled={sup}
                            onClick={() => supr(item[".id"]?.replace("*", ""))}
                          >
                            Supprimer
                          </button>
                        )}
                      </CheckPermission>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center  fs-20">
                    Aucun utilisateur trouvé
                    <br />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center fs-20">
                  <Loading color="var(--secondary)" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {users.length > 0 && (
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Affichage de{" "}
              {currentPage === 1 ? 1 : (currentPage - 1) * sort + 1} à{" "}
              {currentPage === 1
                ? sort
                : users.length === sort
                ? currentPage * sort
                : (currentPage - 1) * sort + users.length}{" "}
              sur {users_counts} entrées
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="example2_paginate"
            >
              {currentPage === 1 ? (
                ""
              ) : (
                <>
                  <button
                    className="paginate_button previous disabled"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </button>
                </>
              )}

              <span>
                <div className="paginate_button ">{currentPage}</div>
              </span>

              {currentPage >= Math.ceil(users_counts / sort) ? (
                ""
              ) : (
                <>
                  <button
                    className="paginate_button next"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ListUsers;
