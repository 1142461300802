import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { getListSsid } from "../../../services/wifiService";
import AddSsid from "./AddSSID";
import EditSsid from "./EditSSID";

const ListSsid = ({ data }) => {
  return (
    <div>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <strong>#</strong>
            </th>
            <th>
              <strong>SSID</strong>
            </th>
            <th>
              <strong>Mot de passe</strong>
            </th>
            <th>
              <strong>Action</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((wifi, index) => (
            <tr key={index}>
              <td>
                <strong>#{index + 1}</strong>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="w-space-no">{wifi.ssid}</span>
                </div>
              </td>
              <td>{wifi.password}</td>
              <td>
                <div className="d-flex">
                  <button
                    className="btn btn-primary shadow btn-xs sharp me-1"
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

function SectionSsid() {
  const [listeSsid, setListSsid] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(0);
  const [selectedSsid, setSelectedSsid] = React.useState("");
  const [selectedPasword, setSelectedPassword] = React.useState("");
  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleListSsid = () => {
    getListSsid().then((res) => {
      setLoading(false);
      setListSsid(res.data);
    });
  };

  const handleOpenAdd = () => {
    setOpenAddModal(true);
  };

  const handleCloseAdd = () => {
    setOpenAddModal(false);
  };

  const handleOpenEdit = (id, ssid, password) => {
    setSelectedId(id);
    setSelectedSsid(ssid);
    setSelectedPassword(password);
    setOpenEditModal(true);
  };

  const handleCloseEdit = () => {
    setOpenEditModal(false);
  };

  const regetSsid = () => {
    handleListSsid();
  };
  React.useEffect(() => {
    setLoading(true);
    handleListSsid();
  }, []);

  return (
    <div>
      <div
        className="d-flex align-items-center mb-4"
        style={{ justifyContent: "space-between" }}
      >
        <AddSsid
          show={openAddModal}
          handeClose={handleCloseAdd}
          regetSsid={regetSsid}
        />
        <EditSsid
          id={selectedId}
          ssidWifi={selectedSsid}
          password={selectedPasword}
          show={openEditModal}
          handeClose={handleCloseEdit}
          regetSsid={regetSsid}
        />
        <Row style={{ width: "100%" }}>
          <Col lg={12}>
            <Card
              style={{
                width: "100%",
                marginRight: "5%",
              }}
            >
              <Card.Header
                className="d-flex align-items-center flex-wrap"
                style={{ justifyContent: "space-between" }}
              >
                <Card.Title>Liste des appareils connectés</Card.Title>

                <div className="d-flex">
                  <Button variant="secondary" onClick={() => handleOpenAdd()}>
                    <span>Nouveau SSID</span>
                  </Button>
                </div>
              </Card.Header>
              <Card.Body>
                <ListSsid
                  data={listeSsid}
                  loading={loading}
                  handleOpenEdit={handleOpenEdit}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SectionSsid;
